// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        &copy; 2024 Zachary Bohl. All rights reserved. Special thanks to Veronica for the <a className="footer-a" href="https://github.com/veronicadip/windows-lib">WIndows 98 React UI.</a>
      </div>
    </footer>
  );
};

export default Footer;
