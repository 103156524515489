import React from 'react';
import Typography from './components/Typography/Typography';
import './App.css';

import Tabs from './components/Tabs/Tabs';
import TabPanel from './components/TabPanel/TabPanel';
import TabList from './components/TabList/TabList';
import Tab from './components/Tab/Tab';
import Button from './components/Button/Button';
import SmileIcon from "./components/Icons/utopia_smiley.png";

import zach from "./components/zach.jpeg";

import Modal from './components/Modal/Modal';

import InfoIcon from "./components/Icons/msg_information-0.png";

import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <div className="horizontal-container">
        <div className="vertical-container">
     <Typography white type="h1">Hello, World!</Typography>
     <Typography white type="h2">My name's Zach.</Typography>
     <Typography white type="h3">I'm a software engineer.</Typography>

     <Tabs>
        <TabList>
          <Tab value={1}>About Me</Tab>
          <Tab value={2}>Resume</Tab>
          <Tab value={3}>Socials</Tab>
        </TabList>
        <TabPanel value={1}>
          <Typography>
          Greetings! I'm a dedicated software engineer deeply passionate about three distinct realms: low-level coding and Linux, then frontend development and UX design, and finally AI.
          </Typography>
          <Typography>My expertise in low-level programming and Linux involves delving into system architecture and kernel-level optimizations, where I thrive on solving intricate technical challenges to enhance software performance and reliability.
          </Typography>
<Typography>On the frontend and UX design front, I specialize in crafting intuitive user interfaces that prioritize user experience and usability. From wireframing to prototyping, I enjoy transforming complex requirements into sleek, user-friendly designs that seamlessly integrate with backend functionalities.
</Typography>
<Typography>Lastly, my interest in AI revolves around leveraging machine learning algorithms to develop intelligent applications that automate processes and enhance user interactions. I'm particularly fascinated by the potential of AI to revolutionize industries and drive innovation.</Typography>
<Typography>
In summary, I bring a blend of technical rigor and creativity to my work, whether it's optimizing system-level performance or designing elegant user interfaces that elevate the overall user experience.
          </Typography>
        </TabPanel>


        <TabPanel value={2}>
          <a href="https://docs.google.com/document/d/1Lif9Nnqir73wBtAaJJLU1p-74ehpeMje/edit?usp=sharing&ouid=118051418295722767121&rtpof=true&sd=true">
          <Button>
            Click here for my detailed resume! <img src={SmileIcon} alt="smile" />
          </Button>
          </a>
          <Typography>
            2019) BA in English Literature at <a href="https://www.english.txst.edu/">Texas State University:</a> Graduated magna cum laude with a 3.8 GPA.
          </Typography>
          <Typography>
            2021-2022) UX/UI Design Boot Camp at <a href="https://techbootcamps.utexas.edu/ux-ui/landing/?s=Google-Brand&dki=Learn%20UX%20Online&pkw=%2But%20%2Baustin%20%2Bux%20%2Bcertificate&pcrid=468226149548&pmt=b&utm_source=google&utm_medium=cpc&utm_campaign=GGL%7CUT-AUSTIN%7CSEM%7CUX-UI%7C-%7COFL%7CTIER-1%7CALL%7CBRD%7CBMM%7CPrimary%7CCertificate&utm_term=%2But%20%2Baustin%20%2Bux%20%2Bcertificate&s=google&k=%2But%20%2Baustin%20%2Bux%20%2Bcertificate&utm_adgroupid=109140088799&utm_locationphysicalms=9028305&utm_matchtype=b&utm_network=g&utm_device=c&utm_content=468226149548&utm_placement=&gad_source=1&gclid=Cj0KCQjwv7O0BhDwARIsAC0sjWMDqBYgPJuCACZDfx15_b76lyWNyukmJBA7Qv7-PLlAXinTQH4dfsQaAhjXEALw_wcB&gclsrc=aw.ds">University of Texas:</a> Completed an intensive 6-month boot camp focused on UX/UI design principles and best practices.
          </Typography>
          <Typography>
            2023-2025) MS in Computer Science at <a href="https://www.smu.edu/lyle">Southern Methodist University:</a> Currently pursuing a master's degree in computer science with a focus on AI and machine learning, part-time (night classes).
          </Typography>
          <Typography>
            2023-Present) Freelancer at <a href="https://www.upwork.com/freelancers/~019342dad5777883de">Upwork:</a> Providing and imporoving my web development and UX skills through commissioned work.
          </Typography>
          <Typography>
            2024-Present) Part-Time WordPress Developer at <a href="https://theenergycouncil.org/">The Energy Council:</a> Implementing and iterating on features for WordPress site, as well as providing education about WordPress best practices.
          </Typography>
          <Typography>
            2024-Present) Web Developer and Consultatnt at <a href="https://batcityarchery.org">Bat City Archery:</a> Developing and maintaining a website for a local nonprofit, as well as providing consultation on web development best practices.
          </Typography>

        </TabPanel>

        <TabPanel value={3}>
          <Typography>
          Follow me on my socials!
          </Typography>
          <Typography>
          - <a href="https://www.linkedin.com/in/zachary-bohl-2092581ab/">LinkedIn</a>
          </Typography>
          <Typography>
          - <a href="https://github.com/zach1020">GitHub</a>
          </Typography>
          <Typography>
          - <a href="https://x.com/Elroy_Muscato">X</a>
          </Typography>
          <Typography>
          - <a href="https://www.capybara-watanabe.net/">CapyNet (my blog)</a>
          </Typography>
          <Typography>
          - <a href="https://soundcloud.com/capybara-watanabe">SoundCloud</a>
          </Typography>
        </TabPanel>

     </Tabs>
     </div>
     <img className="pfp" src={zach} alt="Zach" />
    </div>
    <div className="vertical-container">
    <Typography white type="h2">Some Projects of Mine</Typography>
    <div className="project-container">
      <Modal title="Project" buttonContent="Chip8 Emulator">
      <div className="modalContent">
        <div>
          <img src={InfoIcon} alt="info" />
        
        <div className="modalInfo">
          <Typography bold type="h6">
            Chip8 Emulator
          </Typography>
          <Typography>
            A Chip8 emulator written in C++ that emulates the Chip8 system and runs Chip8 ROMs.
          </Typography>
          <Typography>
            <a href="https://github.com/zach1020/Chip-8-Emulator">Check it out on GitHub!</a>
            </Typography>
        </div>
      </div>
      </div>
      </Modal>

      <Modal title="Project" buttonContent="Packet Sniffer">
      <div className="modalContent">
        <div>
          <img src={InfoIcon} alt="info" />
        
        <div className="modalInfo">
          <Typography bold type="h6">
            Packet Sniffer
          </Typography>
          <Typography>
            A Chip8 emulator written in C++ that emulates the Chip8 system and runs Chip8 ROMs.
          </Typography>
          <Typography>
            <a href="https://github.com/zach1020/packet_sniffer">Check it out on GitHub!</a>
            </Typography>
        </div>
      </div>
      </div>
      </Modal>

      <Modal title="Project" buttonContent="Very Random">
      <div className="modalContent">
        <div>
          <img src={InfoIcon} alt="info" />
        
        <div className="modalInfo">
          <Typography bold type="h6">
           Very Random
          </Typography>
          <Typography>
            A TRUE random number generator written in Python that leverages IBM's quiskit library to generate random numbers using real quantum mechanincs.
          </Typography>
          <Typography>
            <a href="https://github.com/zach1020/veryrandom">Check it out on GitHub!</a>
            </Typography>
        </div>
      </div>
      </div>
      </Modal>

      <Modal title="Project" buttonContent="RustybaraOS">
      <div className="modalContent">
        <div>
          <img src={InfoIcon} alt="info" />
        
        <div className="modalInfo">
          <Typography bold type="h6">
            RustyBaraOS
          </Typography>
          <Typography>
            A simple, extensible operating system written in Rust. This is a work in progress! Capybaras are cool.
          </Typography>
          <Typography>
            <a href="https://github.com/zach1020/RustybaraOS">Check it out on GitHub!</a>
            </Typography>
        </div>
      </div>
      </div>
      </Modal>

      <Modal title="Project" buttonContent="Drive a Senior Design">
      <div className="modalContent">
        <div>
          <img src={InfoIcon} alt="info" />
        
        <div className="modalInfo">
          <Typography bold type="h6">
            Drive a Senior Design
          </Typography>
          <Typography>
            A UX design project focused on creating a user-friendly interface for a local nonprofit that provides transportation services to seniors. BONUS: THEY USED OUR DESIGN!
          </Typography>
          <Typography>
            <a href="https://driveaseniornorthwest.org/">Check out the final product!</a>
            </Typography>
        </div>
      </div>
      </div>
      </Modal>

      <Modal title="Project" buttonContent="New Chat">
      <div className="modalContent">
        <div>
          <img src={InfoIcon} alt="info" />
        
        <div className="modalInfo">
          <Typography bold type="h6">
            New Chat
          </Typography>
          <Typography>
            A Python script that allows the user to have a spoken conversation with ChatGPT (before that was actually a thing...).
          </Typography>
          <Typography>
            <a href="https://github.com/zach1020/new-chat">Check it out on GitHub!</a>
            </Typography>
        </div>
      </div>
      </div>
      </Modal>

    </div>
    </div>
    <div className="spacer" /> 
    <Footer />
    </div>
   
  );
}

export default App;
